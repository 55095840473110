import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyBQJ0im7tI7353mny-K3jpocHY9x0n_yvk",
  authDomain: "smallbizexpo-admin.firebaseapp.com",
  databaseURL: "https://smallbizexpo-admin.firebaseio.com",
  projectId: "smallbizexpo-admin",
  storageBucket: "smallbizexpo-admin.appspot.com",
  messagingSenderId: "537107637817",
  appId: "1:537107637817:web:c17edad53c9bb9c2068fe8",
  measurementId: "G-KJ81W7K9L0"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db = firebase.database();