import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Profiles from '../components/Profiles';
import ProfileForm from '../components/ProfileForm';
import ExpoDetailsForm from '../components/ExpoDetailsForm';
import SlidingPane from 'react-sliding-pane';
import ExhibitorsList from '../components/ExhibitorsList';
import ExhibitorDetailsForm from '../components/ExhibitorDetailsForm';
import SpeakersList from '../components/SpeakersList';
import SpeakerDetailsForm from '../components/SpeakerDetailsForm';

import { auth } from '../services/firebase';
import { db } from '../services/firebase';
import { formatDate } from '../helpers/globalFunctions';

class Expo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      approvedUser: true,
      selectedExpoDetails: this.props.selectedExpoDetails,
      profilesList: this.props.profilesList,
      exhibitorsList: [],
      filteredExhibitors: [],
      selectedProfileId: '',
      selectedExpoId: '',
      writeError: null,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      paneComponent: '',
      newExpoFlag: false,
      isExhibitorsPaneOpen: false,
      selectedExpoTitle: '',
      isExhibitorDetailsOpen: false,
      isSpeakersPaneOpen: false,
      isSpeakerDetailsPaneOpen: false,
      selectedExhibitorId: '',
      selectedSpeakerId: '',
      isViewMode: true,
      totalBumpedInExhibitors: 0,
    };
    this.exposAreaRef = React.createRef();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this.setState({ readExhibitorsError: null, loadingExhibitorsList: true });

    try {
      await db
        .ref('/expos/' + this.props.selectedExpoDetails[0].key + '/exhibitors')
        .on('value', (snapshot) => {
          let exhibitorsList = [];

          snapshot.forEach((snap) => {
            let currentSnap = snap.val();

            currentSnap['key'] = snap.key;
            exhibitorsList.push(currentSnap);
          });

          exhibitorsList.sort(function (a, b) {
            return a.table_ref - b.table_ref;
          });

          let totalBumpedInExhibitors = exhibitorsList.filter(
            (exhibitorDetails) => exhibitorDetails.status === 'In'
          );

          this.state.totalBumpedInExhibitors = totalBumpedInExhibitors.length;
          this.state.exhibitorsList = exhibitorsList;
          this.state.filteredExhibitors = exhibitorsList;
          //          ExhibitorsListArea.scrollBy(0, ExhibitorsListArea.scrollHeight);
        });
    } catch (error) {
      this.setState({
        readExhibitorsError: error.message,
        loadingExhibitorsList: false,
      });
    }
    this.setState({ loadingExhibitorsList: true });
  }

  handleProfileButtonClick = (selectedProfileId) => {
    this.setState({
      paneComponent: 'Profile',
      selectedProfileId: selectedProfileId,
      isPaneOpen: true,
    });
  };

  handleExhibitorsListClick = (selectedExpoId) => {
    let expoTitle =
      this.props.selectedExpoDetails[0].expo_name +
      ' - ' +
      formatDate(this.props.selectedExpoDetails[0].expo_date);

    this.setState({
      expoTitle: {},
      selectedExpoId: selectedExpoId,
      selectedExpoTitle: expoTitle,
      isExhibitorsPaneOpen: true,
    });
  };

  handleSpeakersListClick = (selectedExpoId) => {
    let expoTitle =
      this.props.selectedExpoDetails[0].expo_name +
      ' - ' +
      formatDate(this.props.selectedExpoDetails[0].expo_date);

    this.setState({
      expoTitle: {},
      selectedExpoId: selectedExpoId,
      selectedExpoTitle: expoTitle,
      isSpeakersPaneOpen: true,
    });
  };

  handleNewExhibitorButtonClick = (selectedExpoId) => {
    try {
      let newExhibitorDetails = {
        expo_id: this.state.selectedExpoId,
        zone_area: '',
        table_ref: '',
        business_name: '',
        powered_table: false,
        status: '',
        notes: '',
      };
      db.ref('/expos/' + selectedExpoId + '/exhibitors')
        .push(newExhibitorDetails)
        .then((snap) => {
          this.setState({
            selectedExhibitorId: snap.key,
            isExhibitorDetailsOpen: true,
          });
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  };

  handleNewSpeakerButtonClick = (selectedExpoId) => {
    console.log('New Speaker created....', selectedExpoId);
    try {
      let newSpeakerDetails = {
        expo_id: this.state.selectedExpoId,
        group_name: '',
        speaker_order_ref: '',
        time_slot: '',
        speaker_name: false,
        intro_script: '',
        notes: '',
        status: 'allocated',
      };
      db.ref('/expos/' + selectedExpoId + '/speakers')
        .push(newSpeakerDetails)
        .then((snap) => {
          this.setState({
            selectedExhibitorId: snap.key,
            isSpeakerDetailsPaneOpen: true,
          });
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  };

  handleExhibitorItemClick = (selectedExhibitorId) => {
    this.setState({
      selectedExpoId: this.props.selectedExpoDetails[0].key,
      selectedExhibitorId: selectedExhibitorId,
      isViewMode: true,
      isExhibitorDetailsOpen: true,
    });
  };

  handleSpeakerItemClick = (selectedSpeakerId) => {
    this.setState({
      selectedExpoId: this.props.selectedExpoDetails[0].key,
      selectedSpeakerId: selectedSpeakerId,
      isViewMode: true,
      isSpeakerDetailsPaneOpen: true,
    });
  };

  handleBusinessNameFilterChange = (event) => {
    let filteredExhibitors = this.state.exhibitorsList.filter(
      (exhibitorDetails) =>
        exhibitorDetails.business_name
          .toString()
          .toLowerCase()
          .includes(event.target.value)
    );

    this.setState({
      businessNameFilter: event.target.value,
      filteredExhibitors,
    });
  };

  render() {
    return (
      <div>
        <div className='row  mt-1 py-3 px-3'>
          {!this.state.approvedUser ? (
            <React.Fragment>
              <div className='col'>
                Sorry you do not have access to this section.
                <br />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='col expos-list-section' ref={this.exposAreaRef}>
                {/* loading indicator */}
                {this.state.loadingExposList ? (
                  <div className='spinner-border text-success' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                  ''
                )}

                {/* expo summary area */}
                <React.Fragment>
                  {this.props.selectedExpoDetails.map((expoDetail) => {
                    return (
                      <div key={expoDetail.key} className='mt-1 py-3 px-3'>
                        <h4>
                          {expoDetail.expo_name} -{' '}
                          {formatDate(expoDetail.expo_date)}
                        </h4>
                        <p>
                          {expoDetail.expo_address
                            .split('\n')
                            .map((item, i) => {
                              return (
                                <span key={i}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}{' '}
                          <a
                            href={expoDetail.epxo_google_map_url}
                            rel='noreferrer'
                            target='_blank'>
                            Open in Google Maps
                          </a>
                        </p>
                        <p></p>
                        <p>
                          Total Exhibitors: {this.state.exhibitorsList.length}
                        </p>
                        <p
                          style={{
                            backgroundColor: '#daff91',
                            maxWidth: '180px',
                          }}>
                          Total Bumped In: {this.state.totalBumpedInExhibitors}
                        </p>

                        {expoDetail.expo_map_file_1_label &&
                        expoDetail.expo_map_file_1_label.length > 2 ? (
                          <p>
                            {' '}
                            <a href={expoDetail.expo_map_file_1_url}>
                              {expoDetail.expo_map_file_1_label}
                            </a>
                          </p>
                        ) : (
                          ''
                        )}

                        {expoDetail.expo_map_file_2_label &&
                        expoDetail.expo_map_file_2_label.length > 2 ? (
                          <p>
                            {' '}
                            <a href={expoDetail.expo_map_file_2_url}>
                              {expoDetail.expo_map_file_2_label}
                            </a>
                          </p>
                        ) : (
                          ''
                        )}
                        <button
                          //key={expoDetail.key}
                          className={'m-3 expo-item btn-primary btn'}
                          onClick={() =>
                            this.handleExhibitorsListClick(expoDetail.key)
                          }>
                          Exhibitors List
                        </button>
                        {this.props.currentUserDetails.currentUserRoleLevel ===
                        'admin' ? (
                          <React.Fragment>
                            <button
                              //key={expoDetail.key}
                              className={'m-3 expo-item btn-primary btn'}
                              onClick={() =>
                                this.handleSpeakersListClick(expoDetail.key)
                              }>
                              Speakers List
                            </button>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {this.props.currentUserDetails.currentUserRoleLevel ===
                        'admin' ? (
                          <React.Fragment>
                            <button
                              //key={expoDetail.key}
                              className={'m-3 expo-item btn-primary btn'}
                              onClick={() =>
                                this.props.onExpoDetailsClick(expoDetail.key)
                              }>
                              Edit Details
                            </button>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              </div>
              <div className='col profiles-section'>
                <h3>Key Contacts</h3>
                <Profiles
                  profilesList={this.state.profilesList}
                  onProfileButtonClick={this.handleProfileButtonClick}
                />
              </div>
              <SlidingPane
                className='pane-details'
                overlayClassName='pane-details-sliding-pane'
                isOpen={this.state.isPaneOpen}
                title={this.state.paneComponent + ' Details'}
                subtitle=''
                width='85%'
                from='right'
                onRequestClose={() => {
                  this.setState({ isPaneOpen: false });
                }}>
                {this.state.paneComponent === 'Profile' ? (
                  <ProfileForm
                    selectedProfileId={this.state.selectedProfileId}
                    currentUserDetails={this.state.currentUserDetails}
                  />
                ) : (
                  ''
                )}
                {this.state.paneComponent === 'Expo' ? (
                  <ExpoDetailsForm
                    selectedExpoId={this.state.selectedExpoId}
                    newExpoFlag={this.state.newExpoFlag}
                    currentUserId={auth().currentUser.uid}
                  />
                ) : (
                  ''
                )}
              </SlidingPane>
              <SlidingPane
                className='exhibitors-list'
                overlayClassName='exhibitors-list-sliding-pane'
                isOpen={this.state.isExhibitorsPaneOpen}
                title={<div>Exhibitors - {this.state.selectedExpoTitle}</div>}
                subtitle=''
                width='99%'
                height='800px'
                from='bottom'
                onRequestClose={() => {
                  this.setState({ isExhibitorsPaneOpen: false });
                }}>
                <input
                  type='text'
                  value={this.state.businessNameFilter}
                  onChange={this.handleBusinessNameFilterChange}
                />
                {' Total found: '}
                {this.state.filteredExhibitors.length}

                {/* <button
                  className='float-right new-exhibitor'
                  onClick={() =>
                    this.handleNewExhibitorButtonClick(
                      this.state.selectedExpoId
                    )
                  }>
                  New Exhibitor
                </button>  */}

                <ExhibitorsList
                  filteredExhibitors={this.state.filteredExhibitors}
                  selectedExpoId={this.state.selectedExpoId}
                  selectedExhibitorId={this.state.selectedExhibitorId}
                  onExhibitorItemClick={this.handleExhibitorItemClick}
                  currentUserDetails={this.state.currentUserDetails}
                />

                <SlidingPane
                  className='exhibitors-details'
                  overlayClassName='exhibitors-details-sliding-pane'
                  isOpen={this.state.isExhibitorDetailsOpen}
                  title='Exhibitor Details'
                  subtitle=''
                  width='85%'
                  from='right'
                  onRequestClose={() => {
                    this.setState({ isExhibitorDetailsOpen: false });
                  }}>
                  <ExhibitorDetailsForm
                    selectedExpoId={this.state.selectedExpoId}
                    selectedExhibitorId={this.state.selectedExhibitorId}
                    isViewMode={this.state.isViewMode}
                    currentUserId={auth().currentUser.uid}
                  />
                </SlidingPane>
              </SlidingPane>

              <SlidingPane
                className='speakers-list'
                overlayClassName='speakers-list-sliding-pane'
                isOpen={this.state.isSpeakersPaneOpen}
                title={<div>Speakers - {this.state.selectedExpoTitle}</div>}
                subtitle=''
                width='99%'
                from='bottom'
                onRequestClose={() => {
                  this.setState({ isSpeakersPaneOpen: false });
                }}>
                {
                  <button
                    className='float-right new-exhibitor'
                    onClick={() =>
                      this.handleNewSpeakerButtonClick(
                        this.state.selectedExpoId
                      )
                    }>
                    New Speaker
                  </button>
                }

                <SpeakersList
                  selectedExpoId={this.state.selectedExpoId}
                  selectedSpeakerId={this.state.selectedSpeakerId}
                  onSpeakerItemClick={this.handleSpeakerItemClick}
                  currentUserDetails={this.state.currentUserDetails}
                />

                <SlidingPane
                  className='speaker-details'
                  overlayClassName='speaker-details-sliding-pane'
                  isOpen={this.state.isSpeakerDetailsPaneOpen}
                  title='Speaker Details'
                  subtitle=''
                  width='85%'
                  from='right'
                  onRequestClose={() => {
                    this.setState({ isSpeakerDetailsPaneOpen: false });
                  }}>
                  <SpeakerDetailsForm
                    selectedExpoId={this.state.selectedExpoId}
                    selectedSpeakerId={this.state.selectedSpeakerId}
                    isViewMode={this.state.isViewMode}
                    currentUserId={auth().currentUser.uid}
                  />
                </SlidingPane>
              </SlidingPane>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Expo;
