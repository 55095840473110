import React from 'react';

function Footer() {
  return (
    <footer className='pt-5'>
      <div className='container text-center'>
        <p>&copy; Brilliant Success Biz {new Date().getFullYear()}.</p>
        <span className='footer-version'>v202012090757</span>
      </div>
    </footer>
  );
}

export default Footer;
