import React, { Component } from 'react';
import { db } from '../services/firebase';
import BootstrapTable from 'react-bootstrap-table-next';
import Papa from 'papaparse';

export default class ExhibitorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExpoId: this.props.selectedExpoId,
      filteredExhibitors: this.props.filteredExhibitors,
      loadingExhibitorsList: false,
      readExhibitorsError: '',
      csvfile: undefined,
      currentUserDetails: this.props.currentUserDetails,
      filterValue: '',
      businessNameFilter: '',
    };
    this.ExhibitorsAreaRef = React.createRef();
    this.updateData = this.updateData.bind(this);
  }

  async componentDidMount() {}

  handleChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  updateData(result) {
    let data = result.data;
    const selectedExpoId = this.state.selectedExpoId;

    data.map((item) => {
      //  console.log(item);
      if (!item.Table && !item.Company) {
        // don't import
      } else {
        try {
          let tableNum = Number(item.Table);

          let newExhibitorDetails = {
            expo_id: selectedExpoId,
            zone_area: '',
            table_ref: tableNum,
            sub: item.Sub,
            business_name: item.Company,
            powered_table: item.Powered,
            contact_name: item.Contact,
            mobile_phone: item.Mobile,
            email: '',
            lunch: item.LunchVoucher,
            status: 'Active',
            notes: item.Notes,
          };

          db.ref('/expos/' + selectedExpoId + '/exhibitors')
            .push(newExhibitorDetails)
            .then((snap) => {
              //console.log(snap);
              //this.setState({ selectedExhibitorId: snap.key, isExhibitorDetailsOpen: true });
            });
        } catch (error) {
          console.log('error:', error);
          this.setState({ readError: error.message });
        }
      }
      return true;
    });
  }

  render() {
    let filteredExhibitors = this.props.filteredExhibitors;

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onExhibitorItemClick(row.key);
      },
    };

    const rowStyle = (row, rowIndex) => {
      if (row.status === 'In') {
        return { backgroundColor: '#8EFFAB' };
      }
      if (row.status === 'Rem') {
        return { backgroundColor: '#ffa3a3' };
      }
    };

    const columns = [
      {
        dataField: 'table_ref',
        text: '#',
        type: 'number',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '5%', position: 'sticky' };
        },
      },
      {
        dataField: 'sub',
        text: ' ',
        headerStyle: (colum, colIndex) => {
          return { width: '3%' };
        },
      },
      {
        dataField: 'business_name',
        text: 'Business Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '55%' };
        },
      },
      {
        dataField: 'powered_table',
        text: 'PWR',
      },
      {
        dataField: 'lunch',
        text: 'Food',
      },
    ];

    return (
      <React.Fragment>
        {/* loading indicator */}
        {this.state.loadingExhibitorsList ? (
          <div className='spinner-border text-success' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          ''
        )}

        <BootstrapTable
          striped
          bordered
          hover
          keyField='key'
          data={filteredExhibitors}
          columns={columns}
          rowEvents={rowEvents}
          rowStyle={rowStyle}
        />
        {this.state.currentUserDetails.currentUserRoleLevel === 'admin' ? (
          <React.Fragment>
            <h4>Import CSV File!</h4>
            <input
              className='csv-input'
              type='file'
              ref={(input) => {
                this.filesInput = input;
              }}
              name='file'
              placeholder={null}
              onChange={this.handleChange}
            />
            <p />
            <button onClick={this.importCSV}> Upload now!</button>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
