import React, { Component } from 'react';
import { db } from '../services/firebase';

export default class ExpoDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExpoId: this.props.selectedExpoId,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingExpoDetailsForm: false,
      expoDetails: {
        expo_name: '',
        expo_date: '',
        expo_address: '',
        expo_map_file_1_label: '',
        expo_map_file_1_url: '',
        expo_map_file_2_label: '',
        expo_map_file_2_url: '',
        epxo_google_map_url: '',
      },
    };
    this.myRef = React.createRef();
    this.handleExpoFormChange = this.handleExpoFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingExpoDetailsForm: true });

    let selectedExpoId = this.props.selectedExpoId;
    try {
      let expoDetails = [];

      db.ref('expos/' + selectedExpoId).on('value', (snapshot) => {
        let data = snapshot.val();

        expoDetails = {
          expo_name: data.expo_name,
          expo_date: data.expo_date,
          expo_address: data.expo_address,
          expo_map_file_1_label: data.expo_map_file_1_label,
          expo_map_file_1_url: data.expo_map_file_1_url,
          expo_map_file_2_label: data.expo_map_file_2_label,
          expo_map_file_2_url: data.expo_map_file_2_url,
          epxo_google_map_url: data.epxo_google_map_url,
        };

        this.setState({ expoDetails, loadingExpoDetailsForm: false });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async handleExpoFormChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    db.ref('expos/' + this.props.selectedExpoId).update({
      [name]: value,
    });
  }

  render() {
    const expoDetails = this.state.expoDetails;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingExpoDetailsForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='small'>
              Edit your details below. Changes will be made automatically. Close
              out of this panel when finished
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_name'>
                  Expo Name
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Name'
                  name='expo_name'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_name || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_date'>
                  Expo Date
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Date'
                  name='expo_date'
                  type='date'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_date || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_address'>
                  Expo Address
                </label>
                <textarea
                  className='form-control'
                  placeholder='Expo Address'
                  name='expo_address'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_address || ''}></textarea>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='epxo_google_map_url'>
                  Expo Google Map Link
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Google Map Link'
                  name='epxo_google_map_url'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.epxo_google_map_url || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_map_file_1_label'>
                  Expo Map File 1 Label
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Map File 1 Label'
                  name='expo_map_file_1_label'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_map_file_1_label || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_map_file_1_url'>
                  Expo Map File 1 URL
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Map File 1 URL'
                  name='expo_map_file_1_url'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_map_file_1_url || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_map_file_2_label'>
                  Expo Map File 2 Label
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Map File 2 Label'
                  name='expo_map_file_2_label'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_map_file_2_label || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='expo_map_file_2_url'>
                  Expo Map File 2 URL
                </label>
                <input
                  className='form-control'
                  placeholder='Expo Map File 2 URL'
                  name='expo_map_file_2_url'
                  type='text'
                  onChange={this.handleExpoFormChange}
                  value={expoDetails.expo_map_file_2_url || ''}></input>
              </div>
              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
