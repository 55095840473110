import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../helpers/globalFunctions';
//import ExpoSummary from "../components/ExpoSummary";

export default class ExposList extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.exposList.length > 0 ? (
          <div className='expos-list-area' ref={this.myRef}>
            {this.props.exposList.map((expoDetail) => {
              return (
                <React.Fragment key={expoDetail.key}>
                  {expoDetail.expo_name.length < 1 ? (
                    ' '
                  ) : (
                    <div key={expoDetail.key} className='mt-1 py-3 px-3'>
                      <hr />
                      <div
                        key={expoDetail.key}
                        className='w-75 btn btn btn-outline-dark'
                        onClick={() =>
                          this.props.onExpoItemClick(expoDetail.key)
                        }>
                        <h4>
                          {expoDetail.expo_name} -{' '}
                          {formatDate(expoDetail.expo_date)}
                        </h4>
                        <p>{expoDetail.expo_address}</p>
                      </div>
                      {this.props.currentUserDetails.currentUserRoleLevel ===
                      'admin' ? (
                        <React.Fragment>
                          <br />
                          <button
                            key={expoDetail.key}
                            className={'expo-item btn-primary btn btn-sm'}
                            onClick={() =>
                              this.props.onExpoDetailsClick(expoDetail.key)
                            }>
                            Edit Expo Details
                          </button>
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div>
            <hr />
            <i>There are no expos listed at the moment... </i>
          </div>
        )}
      </React.Fragment>
    );
  }
}
