import React, { Component } from 'react';
import { db } from '../services/firebase';

export default class SpeakerDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpeakerId: this.props.selectedSpeakerId,
      selectedExpoId: this.props.selectedExpoId,
      isViewMode: this.props.isViewMode,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingSpeakerDetailForm: false,
      SpeakerDetails: {
        group_name: '',
        speaker_order_ref: '',
        time_slot: '',
        notes: '',
        speaker_name: '',
        intro_script: '',
        status: '',
      },
    };
    this.myRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingSpeakerDetailForm: true });

    let selectedExpoId = this.props.selectedExpoId;
    let selectedSpeakerId = this.props.selectedSpeakerId;

    try {
      let SpeakerDetails = [];

      db.ref('expos/' + selectedExpoId + '/speakers/' + selectedSpeakerId).on(
        'value',
        (snapshot) => {
          let data = snapshot.val();

          SpeakerDetails = {
            group_name: data.group_name,
            speaker_order_ref: data.speaker_order_ref,
            time_slot: data.time_slot,
            speaker_name: data.speaker_name,
            notes: data.notes,
            intro_script: data.intro_script,
            status: data.status,
            presentation_supplied: data.presentation_supplied,
            speaker_contact_name: data.speaker_contact_name,
            speaker_contact_mobile: data.speaker_contact_mobile,
            speaker_contact_email: data.speaker_contact_email,
          };

          this.setState({ SpeakerDetails, loadingSpeakerDetailForm: false });
        }
      );
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let introScriptFlag = 'No';

    if (name === 'intro_script') {
      if (value.length > 2) {
        introScriptFlag = 'Yes';
      }
      db.ref(
        'expos/' +
          this.props.selectedExpoId +
          '/speakers/' +
          this.props.selectedSpeakerId
      ).update({
        [name]: value,
        intro_script_supplied: introScriptFlag,
      });
    } else {
      db.ref(
        'expos/' +
          this.props.selectedExpoId +
          '/speakers/' +
          this.props.selectedSpeakerId
      ).update({
        [name]: value,
      });
    }
  }

  async handleEditButtonClick(event) {
    this.setState({ isViewMode: false });
  }

  async handleSaveButtonClick(event) {
    this.setState({ isViewMode: true });
  }

  render() {
    const SpeakerDetails = this.state.SpeakerDetails;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingSpeakerDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          {
            /* this.state.isViewMode ? (
            <React.Fragment>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <h4>{SpeakerDetails.business_name || ''}</h4>
                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status
                  </label>

                  <select
                    className=' w-50 form-control w-25'
                    placeholder='Status'
                    name='status'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.status || ''}>
                    <option value='Active'>Active</option>
                    <option value='Bumped In'>Bumped In</option>
                    <option value='Removed'>Removed</option>
                  </select>
                </div>
                <h5>ZONE: {SpeakerDetails.zone_area || ''}</h5>
                <h5>TABLE: {SpeakerDetails.table_ref || ''}</h5>
                <h5>POWER: {SpeakerDetails.powered_table || ''}</h5>
                <h5>NOTES: {SpeakerDetails.notes || ''}</h5>
                <button
                  className='edit-mode btn-primary btn btn-sm'
                  onClick={this.handleEditButtonClick}>
                  Edit
                </button>
              </form>
            </React.Fragment>
          ) : (  */
            <React.Fragment>
              <div className='small'>
                Edit details below. Changes will be made automatically. Close
                out of this panel when finished
              </div>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <div className='d-flex flex-row'>
                  <div className='form-group'>
                    <label className='form_label' htmlFor='group_name'>
                      Group
                    </label>

                    <select
                      style={{}}
                      readOnly={this.state.isViewMode}
                      className='form-control'
                      placeholder='Group'
                      name='group_name'
                      onChange={this.handleInputChange}
                      value={SpeakerDetails.group_name || ''}>
                      <option value='Jobs'>Jobs</option>
                      <option value='V2B'>Voice To Your Business</option>
                      <option value='TinaX'>TinaX</option>
                      <option value='Other'>Other</option>
                    </select>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='speaker_order_ref'>
                      Order #
                    </label>
                    <input
                      style={{}}
                      readOnly={this.state.isViewMode}
                      className='form-control'
                      placeholder='Order'
                      name='speaker_order_ref'
                      type='text'
                      onChange={this.handleInputChange}
                      value={SpeakerDetails.speaker_order_ref || ''}></input>
                  </div>

                  <div className='form-group'>
                    <label className='form_label' htmlFor='time_slot'>
                      Time Slot
                    </label>
                    <input
                      readOnly={this.state.isViewMode}
                      className='form-control'
                      placeholder='Time Slot'
                      name='time_slot'
                      type='text'
                      onChange={this.handleInputChange}
                      value={SpeakerDetails.time_slot || ''}></input>
                  </div>
                  <div className='form-group'>
                    <label
                      className='form_label'
                      htmlFor='presentation_supplied'>
                      Presentation/Logo
                    </label>
                    <select
                      readOnly={this.state.isViewMode}
                      className='form-control'
                      placeholder='Presentation/Logo'
                      name='presentation_supplied'
                      onChange={this.handleInputChange}
                      value={SpeakerDetails.presentation_supplied || ''}>
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </select>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='speaker_name'>
                    Speaker Name
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Speaker Name'
                    name='speaker_name'
                    type='text'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.speaker_name || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status
                  </label>

                  <select
                    className='form-control'
                    placeholder='Status'
                    name='status'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.status || ''}>
                    <option value='Allocated'>Allocated</option>
                    <option value='Confirmed'>Confirmed</option>
                    <option value='Removed'>Removed</option>
                  </select>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>

                  <textarea
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.notes || ''}></textarea>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='intro_script'>
                    Intro Script
                  </label>
                  <textarea
                    rows='30'
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Intro Script'
                    name='intro_script'
                    type='text'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.intro_script || ''}></textarea>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='speaker_contact_name'>
                    Speaker Contact Name
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Speaker Contact Name'
                    name='speaker_contact_name'
                    type='text'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.speaker_contact_name || ''}></input>
                </div>
                <div className='form-group'>
                  <label
                    className='form_label'
                    htmlFor='speaker_contact_mobile'>
                    Speaker Contact Mobile
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Speaker Contact Mobile'
                    name='speaker_contact_mobile'
                    type='text'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.speaker_contact_mobile || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='speaker_contact_email'>
                    Speaker Contact Email
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Speaker Contact Email'
                    name='speaker_contact_email'
                    type='text'
                    onChange={this.handleInputChange}
                    value={SpeakerDetails.speaker_contact_email || ''}></input>
                </div>

                <div className='form-group'>
                  {this.state.error ? (
                    <p className='text-danger'>{this.state.error}</p>
                  ) : null}
                </div>
              </form>

              {this.state.isViewMode ? (
                <button
                  className='edit-mode btn-primary btn btn-sm'
                  onClick={this.handleEditButtonClick}>
                  Edit
                </button>
              ) : (
                <button
                  className='save-button btn-primary btn btn-sm'
                  onClick={this.handleSaveButtonClick}>
                  Save
                </button>
              )}
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}
