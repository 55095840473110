import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import Home from './pages/Home';
import MainApp from './pages/Mainapp';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { auth } from './services/firebase';
import { db } from './services/firebase';
import './styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

function PrivateRoute({
  component: Component,
  authenticated,
  selectedExpoId,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} selectedExpoId={selectedExpoId} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to='/sbe-admin' />
        )
      }
    />
  );
}

async function createNewProfile(user) {
  // console.log(user);

  await db
    .ref('/profiles')
    .orderByChild('uid')
    .equalTo(user.uid)
    .once('value')
    .then(function (snapshot) {
      if (snapshot.val() === null) {
        try {
          let newProfile = {
            display_name: user.displayName,
            mobile_phone: user.phoneNumber,
            email_address: user.email,
            address: '',
            approved_user: false,
            role_level: 'view',
            profile_complete: false,
            uid: user.uid,
          };
          db.ref('/profiles').push(newProfile);
        } catch (error) {
          // this.setState({ readError: error.message, loadingProfiles: false });
        }
      } else {
        /*   Do nothing at the moment */
      }
      // console.log(snapshot.val());
    })
    .catch(function (next) {
      // console.log(next);
    });
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      selectedExpoId: '',
      currentUserDetails: [],
      loading: true,
    };
  }

  async componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        createNewProfile(user);

        this.setState({ authenticated: true, loading: false });
      } else {
        this.setState({ authenticated: false, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <div
        className='spinner-border text-success loading-indicator'
        role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    ) : (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <PrivateRoute
            path='/sbe-admin'
            authenticated={this.state.authenticated}
            component={MainApp}
          />

          <PublicRoute
            path='/signup'
            authenticated={this.state.authenticated}
            component={Signup}
          />
          <PublicRoute
            path='/login'
            authenticated={this.state.authenticated}
            component={Login}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
