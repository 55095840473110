import React, { Component } from 'react';
import { db } from '../services/firebase';

export default class ExhibitorDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExhibitorId: this.props.selectedExhibitorId,
      selectedExpoId: this.props.selectedExpoId,
      isViewMode: this.props.isViewMode,
      currentUserId: this.props.currentUserId,
      readError: null,
      writeError: null,
      loadingExhibitorDetailForm: false,
      ExhibitorDetails: {
        zone_area: '',
        table_ref: '',
        business_name: '',
        notes: '',
        power: null,
        status: '',
      },
    };
    this.myRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingExhibitorDetailForm: true });

    let selectedExpoId = this.props.selectedExpoId;
    let selectedExhibitorId = this.props.selectedExhibitorId;

    try {
      let ExhibitorDetails = [];

      db.ref(
        'expos/' + selectedExpoId + '/exhibitors/' + selectedExhibitorId
      ).on('value', (snapshot) => {
        let data = snapshot.val();

        ExhibitorDetails = {
          zone_area: data.zone_area,
          table_ref: data.table_ref,
          sub: data.sub,
          business_name: data.business_name,
          notes: data.notes,
          power: data.powered_table,
          status: data.status,
        };

        this.setState({ ExhibitorDetails, loadingExhibitorDetailForm: false });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    db.ref(
      'expos/' +
        this.props.selectedExpoId +
        '/exhibitors/' +
        this.props.selectedExhibitorId
    ).update({
      [name]: value,
    });
  }

  async handleEditButtonClick(event) {
    this.setState({ isViewMode: false });
  }

  async handleSaveButtonClick(event) {
    this.setState({ isViewMode: true });
  }

  render() {
    const ExhibitorDetails = this.state.ExhibitorDetails;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingExhibitorDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          {
            /* this.state.isViewMode ? (
            <React.Fragment>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <h4>{ExhibitorDetails.business_name || ''}</h4>
                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status
                  </label>

                  <select
                    className=' w-50 form-control w-25'
                    placeholder='Status'
                    name='status'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.status || ''}>
                    <option value='Active'>Active</option>
                    <option value='Bumped In'>Bumped In</option>
                    <option value='Removed'>Removed</option>
                  </select>
                </div>
                <h5>ZONE: {ExhibitorDetails.zone_area || ''}</h5>
                <h5>TABLE: {ExhibitorDetails.table_ref || ''}</h5>
                <h5>POWER: {ExhibitorDetails.powered_table || ''}</h5>
                <h5>NOTES: {ExhibitorDetails.notes || ''}</h5>
                <button
                  className='edit-mode btn-primary btn btn-sm'
                  onClick={this.handleEditButtonClick}>
                  Edit
                </button>
              </form>
            </React.Fragment>
          ) : (  */
            <React.Fragment>
              <div className='small'>
                Edit details below. Changes will be made automatically. Close
                out of this panel when finished
              </div>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <div className='form-group'>
                  <label className='form_label' htmlFor='business_name'>
                    Business Name
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Business Name'
                    name='business_name'
                    type='text'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.business_name || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status
                  </label>

                  <div className='switch-field'>
                    <input
                      id='radio-in'
                      type='radio'
                      value='In'
                      checked={ExhibitorDetails.status === 'In' ? true : false}
                      name='status'
                      onChange={this.handleInputChange}
                    />{' '}
                    <label htmlFor='radio-in'>Bumped In</label>
                    <input
                      id='radio-act'
                      type='radio'
                      value='Act'
                      checked={ExhibitorDetails.status === 'Act' ? true : false}
                      name='status'
                      onChange={this.handleInputChange}
                    />{' '}
                    <label htmlFor='radio-act'>Active</label>
                    <input
                      id='radio-rem'
                      type='radio'
                      value='Rem'
                      checked={ExhibitorDetails.status === 'Rem' ? true : false}
                      name='status'
                      onChange={this.handleInputChange}
                    />{' '}
                    <label htmlFor='radio-rem'>Removed</label>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='notes'>
                    Notes
                  </label>

                  <textarea
                    className='form-control'
                    placeholder='Notes'
                    name='notes'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.notes || ''}></textarea>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='zone_area'>
                    Zone/Area
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Zone/Area'
                    name='zone_area'
                    type='text'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.zone_area || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='table_ref'>
                    Table #
                  </label>
                  <input
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Table #'
                    name='table_ref'
                    type='text'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.table_ref || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='power'>
                    Power
                  </label>
                  <select
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Power'
                    name='power'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.powered_table || ''}>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='lunch'>
                    Voucher
                  </label>
                  <select
                    readOnly={this.state.isViewMode}
                    className='form-control'
                    placeholder='Voucher'
                    name='lunch'
                    onChange={this.handleInputChange}
                    value={ExhibitorDetails.lunch || ''}>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>

                <div className='form-group'>
                  {this.state.error ? (
                    <p className='text-danger'>{this.state.error}</p>
                  ) : null}
                </div>
              </form>

              {this.state.isViewMode ? (
                <button
                  className='edit-mode btn-primary btn btn-sm'
                  onClick={this.handleEditButtonClick}>
                  Edit
                </button>
              ) : (
                <button
                  className='save-button btn-primary btn btn-sm'
                  onClick={this.handleSaveButtonClick}>
                  Save
                </button>
              )}
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}
