export function formatDate(expo_date) {
  const d = new Date(expo_date);
  const format_date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  return format_date;
}

export function formatDateYYYYMMDD(inputDate) {
  const d = new Date(inputDate);

  const YYYY = d.getFullYear();
  let MM = d.getMonth() + 1;
  let DD = d.getDate();
  let HH = d.getHours();
  let mm = d.getMinutes();
  let ss = d.getSeconds();

  if (DD < 10) {
    DD = `0${DD}`;
  }
  if (MM < 10) {
    MM = `0${MM}`;
  }

  if (HH < 10) {
    HH = `0${HH}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }

  const format_date = YYYY + '-' + MM + '-' + DD;
  return format_date;
}

export function getCurrentDate(separator = '') {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}
