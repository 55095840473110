import React, { Component } from 'react';
import { db } from '../services/firebase';
import BootstrapTable from 'react-bootstrap-table-next';
import Papa from 'papaparse';

export default class SpeakersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExpoId: this.props.selectedExpoId,
      selectedSpeakerId: this.props.selectedSpeakerId,
      speakersList: [],
      filteredSpeakers: [],
      loadingSpeakersList: false,
      readSpeakersError: '',
      currentUserDetails: this.props.currentUserDetails,
      filterValue: '',
    };
    this.SpeakersAreaRef = React.createRef();
    this.updateData = this.updateData.bind(this);
  }

  async componentDidMount() {
    this.setState({ readSpeakersError: null, loadingSpeakersList: true });
    //  const speakersListArea = this.ExhibitorsAreaRef.current;

    try {
      await db
        .ref('/expos/' + this.props.selectedExpoId + '/speakers')
        .on('value', (snapshot) => {
          let speakersList = [];

          snapshot.forEach((snap) => {
            let currentSnap = snap.val();
            currentSnap['key'] = snap.key;
            speakersList.push(currentSnap);
          });

          speakersList.sort(function (a, b) {
            return a.table_ref - b.table_ref;
          });

          this.setState({
            speakersList: speakersList,
            filteredSpeakers: speakersList,
          });
          //          speakersListArea.scrollBy(0, speakersListArea.scrollHeight);
          this.setState({ loadingSpeakersList: false });
        });
    } catch (error) {
      this.setState({
        readSpeakersError: error.message,
        loadingSpeakersList: false,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  /* import CSV data into database */
  updateData(result) {
    let data = result.data;
    const selectedExpoId = this.state.selectedExpoId;

    data.map((item) => {
      //  console.log(item);
      if (!item.Table && !item.Company) {
        // don't import
      } else {
        try {
          let tableNum = Number(item.Table);
          console.log(tableNum);
          let newSpeakerDetails = {
            expo_id: selectedExpoId,
            zone_area: '',
            table_ref: tableNum,
            sub: item.Sub,
            business_name: item.Company,
            powered_table: item.Powered,
            contact_name: item.Contact,
            mobile_phone: item.Mobile,
            email: item.Email,
            lunch: item.LunchVoucher,
            status: 'Active',
            notes: item.Notes,
            intro_script_supplied: item.intro_script_supplied,
            presentation_supplied: item.presentation_supplied,
          };

          db.ref('/expos/' + selectedExpoId + '/speakers')
            .push(newSpeakerDetails)
            .then((snap) => {
              //console.log(snap);
              //this.setState({ selectedExhibitorId: snap.key, isspeakerDetailsOpen: true });
            });
        } catch (error) {
          console.log('error:', error);
          this.setState({ readError: error.message });
        }
      }
      return true;
    });
  }

  handleSpeakerNameFilterChange = (event) => {
    let filteredSpeakers = this.state.speakersList.filter((speakerDetails) =>
      speakerDetails.speaker_name
        .toString()
        .toLowerCase()
        .includes(event.target.value)
    );

    this.setState({ filteredSpeakers: filteredSpeakers });
  };

  render() {
    let filteredSpeakers = this.state.filteredSpeakers;

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onSpeakerItemClick(row.key);
      },
    };

    const rowStyle = (row, rowIndex) => {
      if (
        row.presentation_supplied === 'No' &&
        row.intro_script_supplied === 'No'
      ) {
        return { backgroundColor: '#ffa3a3' };
      }
      if (
        row.presentation_supplied === 'No' &&
        row.intro_script_supplied === 'Yes'
      ) {
        return { backgroundColor: '#fcba03' };
      }
      if (
        row.presentation_supplied === 'Yes' &&
        row.intro_script_supplied === 'No'
      ) {
        return { backgroundColor: '#fcba03' };
      }

      if (
        row.presentation_supplied === 'Yes' &&
        row.intro_script_supplied === 'Yes'
      ) {
        return { backgroundColor: '#8EFFAB' };
      }
    };

    const columns = [
      {
        dataField: 'group_name',
        text: 'Session',
        type: 'string',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', position: 'sticky' };
        },
      },
      {
        dataField: 'speaker_order_ref',
        text: '#',
        type: 'number',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '5%' };
        },
      },
      {
        dataField: 'time_slot',
        text: 'Time',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'speaker_name',
        text: 'Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '30%' };
        },
      },

      {
        dataField: 'presentation_supplied',
        text: 'Pres/Logo',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'intro_script_supplied',
        text: 'Intro',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'speaker_contact_name',
        text: 'Contact',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'speaker_contact_mobile',
        text: 'Mobile',
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            <a href={'tel:' + cell}> {cell} </a>
          </div>
        ),
        headerStyle: (colum, colIndex) => {
          return { width: '20%' };
        },
      },
    ];

    return (
      <React.Fragment>
        {/* loading indicator */}
        {this.state.loadingSpeakersList ? (
          <div
            className='spinner-border text-success loading-indicator'
            role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          ''
        )}
        <input
          type='text'
          value={this.state.speakerNameFilter}
          onChange={this.handleSpeakerNameFilterChange}
        />
        {' Total found: '}
        {this.state.filteredSpeakers.length}
        <BootstrapTable
          striped
          bordered
          hover
          keyField='key'
          data={filteredSpeakers}
          columns={columns}
          rowEvents={rowEvents}
          rowStyle={rowStyle}
        />
        {this.state.currentUserDetails.currentUserRoleLevel === 'admin' ? (
          <React.Fragment>
            <h4>Import CSV File!</h4>
            <input
              className='csv-input'
              type='file'
              ref={(input) => {
                this.filesInput = input;
              }}
              name='file'
              placeholder={null}
              onChange={this.handleChange}
            />
            <p />
            <button onClick={this.importCSV}> Upload now!</button>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
