import React, { Component } from 'react';
import Header from '../components/Header';
import ExposList from '../components/ExposList';
import ExpoDetailsForm from '../components/ExpoDetailsForm';
import SlidingPane from 'react-sliding-pane';
import { formatDateYYYYMMDD } from '../helpers/globalFunctions';

import Expo from '../components/Expo';

import { auth } from '../services/firebase';
import { db } from '../services/firebase';

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: {
        currentUserId: auth().currentUser.uid,
        currentUserDisplayName: '',
        currentUserRoleLevel: '',
        currentUserKey: '',
        currentUserApproved: '',
      },
      approvedUser: false,
      exposList: [],
      loadingExposList: true,
      profilesList: [],
      loadingProfilesList: true,
      selectedExpoId: '',
      readExposListError: null,
      writeError: null,
      isExpoDetailsPaneOpen: false,
      newExpoFlag: false,
      selectedExpoTitle: '',
      expoPagedisplay: false,
      upcomingExpos: [],
      selectedExpoDetails: [],
    };
    this.exposAreaRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      readExposListError: null,
      loadingExposList: true,
      readProfilesListError: null,
      loadingProfiles: true,
    });
    const exposListArea = this.exposAreaRef.current;

    try {
      await db.ref('expos').on('value', (snapshot) => {
        //console.log('expos database value', this.state.selectedExpoId);
        let exposList = [];

        snapshot.forEach((snap) => {
          let tempSnap = [];
          let currentSnap = snap.val();

          currentSnap['key'] = snap.key;
          exposList.push(currentSnap);
          tempSnap.push(currentSnap);

          if (currentSnap['key'] === this.state.selectedExpoId) {
            /* there is a current selected expo id so we better update 
              the state for this as well as something has changed */
            this.setState({ selectedExpoDetails: tempSnap });
          }
        });

        let currentDate = formatDateYYYYMMDD(new Date());
        let upcomingExpos = exposList.filter(
          (expoDetails) => expoDetails.expo_date > currentDate
        );
        upcomingExpos.sort((a, b) => {
          return new Date(a.expo_date) - new Date(b.expo_date);
        });

        //upcomingExpos.sort((a, b) => b.expo_date - a.expo_date);

        console.log(upcomingExpos);

        this.setState({ exposList, upcomingExpos });

        if (exposListArea) {
          exposListArea.scrollBy(0, exposListArea.scrollHeight);
        }
        this.setState({ loadingExposList: false });
      });
    } catch (error) {
      this.setState({
        readExposListError: error.message,
        loadingExposList: false,
      });
    }

    try {
      db.ref('profiles').on('value', (snapshot) => {
        let profilesList = [];
        snapshot.forEach((snap) => {
          let currentSnap = snap.val();
          currentSnap['key'] = snap.key;
          profilesList.push(currentSnap);

          if (currentSnap.uid === this.state.currentUserDetails.currentUserId) {
            let currentUserDetails = {
              currentUserId: auth().currentUser.uid,
              currentUserDisplayName: currentSnap.display_name,
              currentUserRoleLevel: currentSnap.role_level,
              currentUserKey: currentSnap.key,
              currentUserApproved: currentSnap.approved_user,
            };
            this.setState({
              currentUserDetails,
              approvedUser: currentSnap.approved_user,
            });
          }
        });
        let currentUserProfile = profilesList.filter((profile) =>
          profile.uid.includes(this.state.currentUserDetails.currentUserId)
        );
        currentUserProfile[0]['current_user'] = true;
        profilesList = profilesList.filter(
          (profile) =>
            !profile.uid.includes(this.state.currentUserDetails.currentUserId)
        );

        profilesList = currentUserProfile.concat(profilesList);

        profilesList.sort(function (a, b) {
          return a.first_name - b.first_name;
        });
        this.setState({ profilesList });
        this.setState({ loadingProfiles: false });
      });
    } catch (error) {
      this.setState({
        readProfilesListError: error.message,
        loadingProfiles: false,
      });
    }
  }

  handleNewExpoButtonClick = () => {
    try {
      let newExpoDetails = {
        expo_name: ' ',
        expo_date: ' ',
        expo_address: ' ',
      };
      db.ref('/expos')
        .push(newExpoDetails)
        .then((snap) => {
          this.setState({
            selectedExpoId: snap.key,
            newExpoFlag: true,
            isExpoDetailsPaneOpen: true,
          });
        });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  };

  handleExpoDetailsClick = (selectedExpoId) => {
    this.setState({
      selectedExpoId: selectedExpoId,
      isExpoDetailsPaneOpen: true,
      newExpoFlag: false,
    });
  };

  handleExpoItemClick = (selectedExpoId) => {
    // filter expos list to just the seleected one - to be sent to the Expo component
    let exposList = this.state.exposList;
    let filtered = exposList.filter((expo) => expo.key === selectedExpoId);
    this.setState({ expoPageDisplay: true, selectedExpoDetails: filtered });
  };

  render() {
    return (
      <div>
        <Header />
        <div className='row  mt-1 py-3 px-3'>
          {!this.state.approvedUser ? (
            <React.Fragment>
              <div className='col'>
                Welcome to the Small Business Expos Admin Site!
                <br />
                Your login details are currently being processed and once you
                have approval, you will be able to continue working.
                <br />
                <br />
                Please check back soon.
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.expoPageDisplay ? (
                <Expo
                  profilesList={this.state.profilesList}
                  currentUserDetails={this.state.currentUserDetails}
                  selectedExpoDetails={this.state.selectedExpoDetails}
                  onExpoDetailsClick={this.handleExpoDetailsClick}
                />
              ) : (
                <React.Fragment>
                  <div
                    className='col expos-list-section'
                    ref={this.exposAreaRef}>
                    {/* loading indicator */}
                    {this.state.loadingExposList ? (
                      <div
                        className='spinner-border text-success loading-indicator'
                        role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    ) : (
                      ''
                    )}

                    {/* expos list area */}
                    <h2>Upcoming Expos</h2>
                    {this.state.currentUserDetails.currentUserRoleLevel ===
                    'admin' ? (
                      <React.Fragment>
                        <button
                          className='new-expo'
                          onClick={this.handleNewExpoButtonClick}>
                          Add a new Expo
                        </button>
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    <ExposList
                      exposList={this.state.upcomingExpos}
                      onExpoDetailsClick={this.handleExpoDetailsClick}
                      onExpoItemClick={this.handleExpoItemClick}
                      currentUserDetails={this.state.currentUserDetails}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <SlidingPane
            className='pane-details'
            overlayClassName='pane-details-sliding-pane'
            isOpen={this.state.isExpoDetailsPaneOpen}
            title='Expo Details'
            subtitle=''
            width='85%'
            from='right'
            onRequestClose={() => {
              this.setState({ isExpoDetailsPaneOpen: false });
            }}>
            <ExpoDetailsForm
              selectedExpoId={this.state.selectedExpoId}
              newExpoFlag={this.state.newExpoFlag}
              currentUserId={auth().currentUser.uid}
            />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

export default MainApp;
